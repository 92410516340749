import './App.css';
import { Link } from "react-router-dom";

const Info = () => {
    return (

        <div className='info-tag'>
            <div className='container'>
                <h5>Chalo Bharat Dekhen | Manohar Agarwal</h5>
                <h5>Astra Tower, Unit No-Aso 431, 4th Floor, Newtown, Rajarhat, Kolkata - 700 156</h5>
                <div className='h2'></div>
                <h5>If you have any questions,concerns or requests related to this privacy policy,</h5>
                <h5 className='mb-0'>please contact us at <a href="mailto:support@chalobharatdekhen.com">support@chalobharatdekhen.com</a> | <a href="tel:+916289991595">+91 62899 91595</a></h5>
            </div>
            <div className='footer-links mt-2'>
                <Link to="/privacy-policy">Privacy Policy</Link>
                <Link to="/terms-and-conditions">Terms & Conditions</Link>
                <Link to="/user-manual">User Manual</Link>
            </div>
        </div>
    )
}
export default Info;