import React, { useState } from 'react';
import { Link } from "react-router-dom";
import playStorelogo from '../../assets/playstore_icon.png'
import applelogo from '../../assets/apple_icon.png';
import chalobharatlogo from '../../assets/chalobharatdekhe.png'
import '../../App.css';
import Info from '../../Info';

const Policy = () => {
    const openPlayStore = () => {
        window.open('https://play.google.com/store/apps/details?id=chalo.bharatdekhen.app&hl=en', '_blank');
    };

    const openAppStore = () => {
        window.open('https://apps.apple.com/in/app/bharat-dekhen/id6478861610', '_blank');
    };
    return (
        <>
            <div className='container'>
                <div className='logoMainView'>
                    <Link to="/">
                        <img src={chalobharatlogo} className="App-logo1" alt="logo" />
                    </Link>
                    <div>
                        <div className='appView'>
                            <p>Click to <br></br>download the app</p>
                        </div>

                        <div className="image-row">
                            <div className="image-container" onClick={openAppStore}>
                                <img src={applelogo} className="Android-logo" alt="Image 1" />
                            </div>
                            <div className="image-container" onClick={openPlayStore}>
                                <img src={playStorelogo} className="Android-logo" alt="Image 2" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='container'>
                <div className='pageHeading'>
                    <h1>Privacy Policy</h1>
                    <h2>Welcome to Chalo Bharat Dekhen</h2>
                    <p>Effective Date: 10.05.2024</p>
                </div>
            </div>

            <div className='container'>
                <ol>
                    <li>
                        <strong>Introduction</strong>
                        <p>
                            This Privacy Policy is intended to inform you about how we collect, use, disclose, and protect your personal information when you use our travel information mobile application. Your privacy is important to us, and we are committed to safeguarding your information. By using our mobile application, you consent to the practices described in this Privacy Policy.
                        </p>
                    </li>

                    <li>
                        <strong>Information We Collect</strong>
                        <ol>
                            <li>
                                <strong>Personal Information</strong>
                                <p>We may collect the following types of personal information:</p>
                                <ul>
                                    <li>
                                        <p>Your name and contact information, such as email address and phone number.</p>
                                    </li>
                                    <li><p>Location information when you enable location services within the app.</p></li>
                                    <li><p>User-generated content, such as reviews and comments.</p></li>
                                    <li><p>Any other information you provide voluntarily.</p></li>
                                </ul>
                            </li>
                            <li>
                                <strong>Non-Personal Information</strong>
                                <p>We may also collect non-personal information, such as:</p>
                                <ul>
                                    <li>
                                        <p>
                                            Device information, including the type of device you use, device ID, operating system, and app version.
                                        </p>
                                    </li>
                                    <li><p>Usage data, such as your interactions with the app, session duration, and features you access.</p></li>
                                </ul>
                            </li>
                        </ol>
                    </li>

                    <li>
                        <strong>How We Use Your Information</strong>
                        <p>We use your information for the following purposes:</p>
                        <ol>
                            <li>
                                <strong>To Provide Services</strong>
                                <p>We use your information to provide you with our travel information and services, including location-based recommendations, travel guides, and personalized content.</p>
                            </li>
                            <li>
                                <strong>Communication</strong>
                                <p>We may use your contact information to send you updates, notifications, and other relevant information related to the app.</p>
                            </li>
                            <li>
                                <strong>Improve and Personalize</strong>
                                <p>We use your information to improve and personalize your experience with the app. This includes analyzing user behavior, identifying user preferences, and making recommendations based on your interests.</p>
                            </li>
                            <li>
                                <strong>Marketing and Advertising</strong>
                                <p>We may use your information to deliver marketing and advertising content to you that we believe may be of interest to you. We do not sell your personal information to third parties for their marketing purposes.</p>
                            </li>
                            <li>
                                <strong>Legal and Security</strong>
                                <p>We may use your information to comply with legal obligations and to protect the security and integrity of the app. This includes detecting and preventing fraudulent activities, abusive usage, and other violations of our terms of service.</p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <strong>How We Share Your Information</strong>
                        <p>We may share your information in the following circumstances:</p>
                        <ol>
                            <li>
                                <strong>Service Providers</strong>
                                <p>We may disclose your information to third-party service providers who assist us in providing our services and maintaining the app. These providers are contractually obligated to protect your information.</p>
                            </li>
                            <li>
                                <strong>Legal Requirements</strong>
                                <p>We may disclose your information when required by law, such as in response to a court order, legal process, or government request.</p>
                            </li>
                            <li>
                                <strong>Business Transfers</strong>
                                <p>If we are involved in a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred as part of that transaction. You will be notified of any such change in ownership or control of your personal information.</p>
                            </li>
                            <li>
                                <strong>Aggregated Data</strong>
                                <p>We may share aggregated and anonymized data that does not personally identify you for research, analysis, and marketing purposes.</p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <strong>Your Choices</strong>
                        <p>You can exercise the following choices regarding your information:</p>
                        <ol>
                            <li>
                                <strong>Access and Update</strong>
                                <p>You can access and update your personal information within the app's settings.</p>
                            </li>
                            <li>
                                <strong>Location Services</strong>
                                <p>You can control location services through your device settings and within the app.</p>
                            </li>
                            <li>
                                <strong>Opt-Out</strong>
                                <p>You may opt out of receiving promotional emails by following the instructions in those emails or contacting us directly.</p>
                            </li>
                            <li>
                                <strong>Data Deletion</strong>
                                <p>You may request the deletion of your account and associated data by contacting us at <a href="mailto:support@chalobharatdekhen.com">support@chalobharatdekhen.com.</a></p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <strong>Security</strong>
                        <p>We take reasonable measures to protect your information from unauthorized access, disclosure, and alteration. However, no method of transmission over the internet or electronic storage is completely secure, so we cannot guarantee absolute security.</p>
                    </li>
                    <li>
                        <strong>Children's Privacy</strong>
                        <p>Our app is not intended for use by children under the age of 13. We do not knowingly collect personal information from children under 13. If you believe a child has provided us with their information, please contact us, and we will promptly delete it.</p>
                    </li>
                    <li>
                        <strong>Changes to this Privacy Policy</strong>
                        <p>We may update this Privacy Policy from time to time to reflect changes in our practices. We will notify you of any significant changes by posting the updated Privacy Policy within the app. Please review the Privacy Policy periodically.</p>
                    </li>
                    <li>
                        <strong>Contact Us</strong>
                        <p>If you have any questions, concerns, or requests related to this Privacy Policy, please contact us at <a href="mailto:support@chalobharatdekhen.com">support@chalobharatdekhen.com</a></p>
                    </li>
                </ol>
            </div>

            <Info />
        </>
    );
}

export default Policy;