import React, { useState } from 'react';
import { Link } from "react-router-dom";
import playStorelogo from '../../assets/playstore_icon.png'
import applelogo from '../../assets/apple_icon.png';
import chalobharatlogo from '../../assets/chalobharatdekhe.png'
import '../../App.css';
import Info from '../../Info';

const Terms = () => {
    const openPlayStore = () => {
        window.open('https://play.google.com/store/apps/details?id=chalo.bharatdekhen.app&hl=en', '_blank');
    };

    const openAppStore = () => {
        window.open('https://apps.apple.com/in/app/bharat-dekhen/id6478861610', '_blank');
      };
    return (
        <>
            <div className='container'>
                <div className='logoMainView'>
                    <Link to="/">
                        <img src={chalobharatlogo} className="App-logo1" alt="logo" />
                    </Link>
                    <div>
                        <div className='appView'>
                            <p>Click to <br></br>download the app</p>
                        </div>

                        <div className="image-row">
                            <div className="image-container" onClick={openAppStore}>
                                <img src={applelogo} className="Android-logo" alt="Image 1" />
                            </div>
                            <div className="image-container" onClick={openPlayStore}>
                                <img src={playStorelogo} className="Android-logo" alt="Image 2" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='container'>
                <div className='pageHeading'>
                    <h1>Terms and Conditions</h1>
                    <h2>Welcome to Chalo Bharat Dekhen</h2>
                    <p>Effective Date: 10.05.2024</p>
                </div>
            </div>

            <div className='container'>
                <ol>
                    <li>
                        <strong>Acceptance of Terms</strong>
                        <p>
                            By using the Chalo Bharat Dekhen mobile application, you agree to comply with and be bound by these Terms and Conditions. If you do not agree with these Terms and Conditions, please do not use the App.
                        </p>
                    </li>

                    <li>
                        <strong>Changes to Terms</strong>
                        <p>We may modify or revise these Terms and Conditions at any time. By using the App after any such changes, you agree to be bound by the updated Terms and Conditions. We will notify you of any significant changes, but it is your responsibility to review this agreement periodically.</p>
                    </li>

                    <li>
                        <strong>Privacy</strong>
                        <p>Your use of the App is also governed by our Privacy Policy, which is incorporated into these Terms and Conditions by reference. Please review the Privacy Policy to understand how we collect, use, and protect your personal information.</p>
                    </li>
                    <li>
                        <strong>User Registration</strong>
                        <p>To access certain features of the App, you may be required to create an account. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. You agree to provide accurate and up-to-date information when creating your account.</p>
                    </li>
                    <li>
                        <strong>User Content</strong>
                        <p>You may be able to submit reviews, comments, and other user-generated content through the App. By submitting such content, you grant us a non-exclusive, worldwide, royalty-free, and perpetual license to use, modify, reproduce, and distribute the content for any purpose.</p>
                        <p>
                            You are solely responsible for the content you submit and any consequences that may result from your submissions. We may, at our discretion, remove or modify any user content that violates these Terms and Conditions or any applicable laws.
                        </p>
                    </li>
                    <li>
                        <strong>Use of the App</strong>
                        <p>You agree not to:</p>
                        <ul>
                            <li>
                                <p>Use the App for any unlawful or unauthorized purpose.</p>
                            </li>
                            <li><p>Interfere with or disrupt the operation of the App.</p></li>
                            <li><p>Upload or transmit any viruses, malware, or other harmful code.</p></li>
                            <li><p>Attempt to gain unauthorized access to our systems or user accounts.</p></li>
                        </ul>
                    </li>
                    <li>
                        <strong>Intellectual Property</strong>
                        <p>All content and materials available on the App, including text, graphics, logos, images, software, and other materials, are protected by copyright and other intellectual property laws. You may not use, reproduce, distribute, or create derivative works from any content on the App without our express written permission.</p>
                    </li>
                    <li>
                        <strong>Termination</strong>
                        <p>We reserve the right to terminate or suspend your access to the App for any reason, including a violation of these Terms and Conditions. We may also discontinue the App at any time without notice.</p>
                    </li>
                    <li>
                        <strong>Disclaimers and Limitation of Liability</strong>
                        <p>The App is provided on an "as-is" and "as available" basis. We make no warranties, express or implied, regarding the accuracy, reliability, or suitability of the App for any particular purpose. You use the App at your own risk.</p>
                        <p>To the fullest extent permitted by law, we disclaim any liability for any direct, indirect, incidental, special, consequential, or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data, or other intangible losses.</p>
                    </li>
                    <li>
                        <strong>Governing Law and Jurisdiction</strong>
                        <p>These Terms and Conditions are governed by and construed in accordance with the laws of Kolkata (India). You agree to submit to the exclusive jurisdiction of the courts in Kolkata, India for the resolution of any disputes.</p>
                    </li>
                    <li>
                        <strong>Contact Information</strong>
                        <p>If you have any questions or concerns about these Terms and Conditions, please contact us at <a href="mailto:support@chalobharatdekhen.com">support@chalobharatdekhen.com</a></p>
                    </li>
                </ol>
            </div>

            <Info />
        </>
    );
}

export default Terms;